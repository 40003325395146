import { actionQueue } from '@@actionsqueue/index';
import * as PersonActionQueue from '@@event/actionsqueue/actionqueue.person';
import * as FavoriteEventThemeActionQueue from '@@event/actionsqueue/actionqueue.favoriteeventtheme';
import { States } from '@@services/services';
import { Entities } from '@inwink/entities/entities';

export const userNetworkingActions = {
    allowNetworking: (allowNetworking: boolean) => (dispatch, getState: () => States.IAppState) => {
        return PersonActionQueue.allowNetworking(dispatch, getState, allowNetworking)
            .then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    },

    networkingPreferences: (prefs: Entities.IPersonNetworkingPreferences) => (dispatch, getState: () => States.IAppState) => {
        return PersonActionQueue.networkingPreferences(dispatch, getState, prefs)
            .then((npmsg) => {
                const oldThemes = npmsg.payload.oldPrefs?.themes?.map((t) => t.eventThemeId) ?? [];
                const currentThemes = npmsg.payload.prefs?.themes?.map((t) => t.eventThemeId) ?? [];
                const addedThemes = currentThemes.filter((id) => oldThemes.indexOf(id) === -1);
                const removedThemes = oldThemes.filter((id) => currentThemes.indexOf(id) === -1);
                const promises: Promise<any>[] = [
                    Promise.resolve()
                ];
                    
                if (addedThemes.length) {
                    promises.push(FavoriteEventThemeActionQueue
                        .addEventThemesToFavorites(dispatch, getState, addedThemes).then((msgs) => {
                            if (msgs?.length) {
                                msgs.forEach((msg) => actionQueue.enqueue(msg, dispatch, getState));
                            }
                        }));
                }

                if (removedThemes.length) {
                    removedThemes.forEach((themeId) => {
                        promises.push(FavoriteEventThemeActionQueue.removeEventThemeFromFavorites(dispatch, getState, themeId)
                            .then((msg) => {
                                actionQueue.enqueue(msg, dispatch, getState);
                            }));
                    });
                }

                return Promise.all(promises).then(() => npmsg);
            });
    },
};
